@import '~react-pro-sidebar/dist/scss/styles.scss';

.chat-app {
  display: flex;

  .pro-sidebar-inner {
    background-color: #fff;
  }

  .current-focused-conversation {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .main-chat {
    flex-grow: 1;
  }

  .btn-toggle {
    cursor: pointer;
    display: none;
  }

  @media (max-width: 768px) {
    overflow-x: hidden;

    .btn-toggle {
      display: inline-block;
    }
  }

  @media (min-width: 768px) {
    .pro-sidebar.md {
      width: 400px;
    }
  }
}
