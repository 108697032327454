:root {
  // --primary-color: #109383; // old from figma - cedric
  --primary-color: #2bbbad; // new from yichen
  --primary-dark-color: #248a809f; // new from harrison
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  // background-color: #E5E5E5;
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

h1,
h2,
h3,
.h2 {
  color: var(--primary-color);
}

.font-weight-bold {
  font-weight: 600;
}

.header-container {
  width: 100%;
  background-color: #ffffff;
  padding: 1.5rem;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.color-white {
  color: #ffffff;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}
