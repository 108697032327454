.search-books-container {
  margin: 0.5rem 0 2rem 0;
  padding: 0 0 2rem 0;
  border-bottom: solid #e5e5e5 1px;
}

.search-books-header {
  color: #898989;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
}
