/*****************************************************/
/********************** Desktop **********************/
/*****************************************************/

.single-card {
  .single-card-image {
    width: 10rem;
    height: 14rem;
  }

  .bookstore-username {
    cursor: pointer;
  }

  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin: 20px 0 20px 0;

  &-container {
    display: flex;
    flex-wrap: nowrap;

    @media screen and (max-width: 767px) {
      .flex-grow-1-mobile {
        display: none;
      }
    }
  }

  &-image {
    border-radius: 10px;
  }

  &__book-title {
    font-size: 28px;
    font-weight: bold;
  }

  &__book-edition {
    color: #898989;
    margin-left: 5px;
  }

  &__book-edition-number {
    font-size: 28px;
    font-weight: bold;
  }

  &__tags {
    > span {
      margin-left: 10px;
      color: #109383;
    }
  }

  // Section 1: Left Section
  .single-card-column-section-1 {
    padding: 10px;
  }

  // Section 2: Middle Section
  .single-card-column-section-2 {
    padding-right: 20px;

    .single-card-column-section-2__row-1 {
      padding: 5px;

      word-break: break-all;
    }

    .single-card-column-section-2__row-2 {
      display: flex;
      padding: 5px;
    }

    .single-card-column-section-2__row-3 {
      padding: 5px;

      .text-authors {
        font-weight: 300;
      }
    }

    .single-card-column-section-2__row-4 {
      padding: 5px;
    }

    .single-card-column-section-2__row-5 {
      padding: 5px;
    }
  }

  // Section 3: Right Section
  .single-card-column-section-3 {
    .money {
      font-size: 2rem;
      font-weight: bold;
    }
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    align-items: center;

    .chat-image {
      width: 40px;
    }

    .bookstore-image {
      width: 40px;
      cursor: pointer;
    }
  }
}

/****************************************************/
/********************** Mobile **********************/
/****************************************************/

@media all and (max-width: 766px) {
  .single-card {
    &__book-edition {
      &-container {
        display: none;
      }
    }

    &-container {
      display: flex;
      flex-wrap: wrap;
    }

    &__book-title {
      font-size: 28px;
      font-weight: bold;
    }

    .single-card-column-section-2__row-4 {
      display: none;
    }

    .single-card-column-section-1 {
      display: none;
    }

    .single-card-column-section-2 {
      width: 100%;

      &__row-1 {
        text-align: left;
      }

      &__row-3 {
        margin: 5px 0 0 0;
      }

      .single-card-column-section-2__row-5 {
        padding: 10px 0 0 0;
      }
    }

    .single-card-column-section-3 {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;

      .money {
        font-size: 25px;
        font-weight: bold;
      }

      .chat-image {
        width: 30px;
      }

      .bookstore-image {
        width: 30px;
      }
    }
  }
}
