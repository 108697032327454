.condition-img {
  width: 8rem;
  margin: 1rem 0rem;
  cursor: pointer;
}

.condition-title {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 767px) {
    justify-content: center;
  }
}
